<template lang='pug'>
    div
        v-row.sheet-box.my-5(v-if='product.length == 0')
            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    h5.ma-0.t-black {{$t('PRODUCTTABLE.NO_PRO')}}
        .case-detail-table.scroll-x.pb-0(v-else)
            .data-area(style='position: relative;')
                v-data-table.height_100.table_in_tab( :headers='productHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" show-select item-key='id' v-model="selectedRows" :items='product' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' multi-sort height='100%' hide-default-footer='hide-default-footer' :loading='isLoading' loading-text='loading...' style='padding-bottom: 45px')
                    template(v-slot:item.name='{ item }')
                        .ellipsis.t-black.text-start(:title='item.name') {{ item.name }}
                    template(v-slot:item.product_code='{ item }')
                        .ellipsis.t-black(:title='item.product_code') {{ item.product_code }}
                    template(v-slot:item.active='{ item }')
                        v-btn(icon @click='showEditProductDialog(item)')
                            v-icon(size='18') icon-edit
                        v-btn(:disabled='item.status  == "closed"' icon='icon' @click='onDelete(item)')
                            v-icon(size='18') icon-remove
                v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10" style='position: absolute; bottom: 0; width: 100%; height: 40px')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='editProductDialog' width='500' content-class='statusDialog' persistent @keydown.esc='onEmitEditDialog(true)')
            editProduct(@emitEditDialog='onEmitEditDialog' :editProduct='editProduct')
</template>
<script>
import messageDialog from '@/components/Dialog/messageDialog';
import CaseDataService from "@/services/CaseDataService";
import editProduct from '@/components/Dialog/Product/editProduct';
import i18n from '/common/plugins/vue-i18n.js' 

export default { 
    props: {
        product: {
            type: Array,
            require: true,
        },
        itemInPage: {
            type: Number,
            require: false,
        },
        deletrProduct: {
            type: Boolean,
            require: true,
        },
    },
    components: {
        editProduct,
        messageDialog,
    },
    data() {
        return {
            oringalData:null,
            editProduct:null,
            selectedRows:[],
            message:'',
            msgDialog_mode:'',
            messageDialog:false,
            editProductDialog:false,
            page: 1,
            pageCount: 0,
            itemPerPage: this.itemInPage ? this.itemInPage : 10,
            productHeaders: [
                {  text: i18n.t('PRODUCTSETTING.PRODUCT'), value: 'name', align: 'center', width: '20%' },
                {  text: i18n.t('PRODUCTSETTING.CATEGORY'), value: 'category.name', align: 'center', width: '20%'},
                {  text: i18n.t('PRODUCTSETTING.SERIES'), value: 'series.name', align: 'center' , width: '20%'},
                {  text: i18n.t('PRODUCTSETTING.PRODUCTCODE'), value: 'product_code', align: 'center' , width: '20%'},
                // {  text: i18n.t('PRODUCTSETTING.PRICR'), value: 'unit_price', align: 'center' , width: '25%'},
                // {  text: i18n.t('PRODUCTSETTING.COST'), value: 'unit_cost', align: 'center' , width: '25%'},
                // {  text: i18n.t('PRODUCTSETTING.DESCRIPTIONL'), value: 'description', align: 'center' , width: '25%'},
                {  text: i18n.t('MANAGEUSERS.ACTIVE'), value: 'active', align: 'center',width: '130', sortable: false,},
            ],
            isLoading: true
        }
    },
    async created(){
        this.isLoading = false
    },
    methods: {        
        onDelete (item) {
                this.deleteItem = item;
                this.msgDialog_mode = 'delete';
                this.showMessageDialog(i18n.t('PRODUCTTABLE.MESSAGE'));
            },
        showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
        onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val) { 
                    // 如果messageDialog是在刪除時被觸發
                    if(this.msgDialog_mode == 'delete'){
                        let delete_id = this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id');
                        let data = {case:delete_id,product:[this.deleteItem.id]};
                        CaseDataService.deletecaseproduct(data).then(()=>{
                            let index =  this.product.indexOf(this.deleteItem);
                            this.product.splice(index, 1);
                            this.msgDialog_mode='';
                        })
                        this.$emit("emitProductDel", this.deleteItem.id);
                    }					
                }
            },
        onMail: function (mail) {
            return 'mailto:'+mail;
        },
        showEditProductDialog(data){
            this.editProductDialog = true
            this.editProduct = data
            this.oringalData = JSON.parse(JSON.stringify(data))
        },
        onEmitEditDialog(data){
            if(data == true)
            {
                data = this.oringalData
            }
            if(data)
            {
                let editItem = this.product.find(el=>{ return el == this.editProduct})
                editItem.name = data.name
                editItem.description = data.description
                editItem.product_code = data.product_code
                editItem.unit_price = data.unit_price
                editItem.unit_price = data.unit_price
                editItem.unit_cost = data.unit_cost
                editItem.series = data.series
                editItem.category = data.category
            }
            this.editProductDialog = false
            this.editProduct = {}
        }
    },
    watch:{
        "selectedRows": async function (){            
            this.$emit("emitProductList", this.selectedRows);
        },
        "deletrProduct": async function (){
            if(!this.deletrProduct)
            this.selectedRows = []
        }
    }
}
</script>